<template>
  <div class="flex-column" style="height: 100vh;">
    <van-nav-bar title="预约记录" left-text="返回" @click-left="back" left-arrow />
    <div class="flex1 tabs" ref="allPage">
      <van-tabs class="tabs100" style="height: 100%;" @change="tabChange">
        <van-tab title="全部">
          <van-pull-refresh
            style="height: 100%;overflow: auto;"
            v-model="isLoading"
            @refresh="loadData"
          >
            <van-list
              ref="reserveList"
              id="reserveList"
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
              class="reserve_list"
            >
              <van-cell
                v-for="(item, index) in allList"
                :key="index"
                class="item_container"
                isLink
                :to="'history/detail?id='+item.id"
                @click="setItemId(item.id)"
              >
                <template #title>
                  <div style="font-size:16px;color:#999999;">
                    <van-icon name="clock-o" />
                    &nbsp;{{item.arrangeDate}}&nbsp;{{item.halfTime=='am'?'上午':'下午'}}&nbsp;{{item.startTime}}-{{item.endTime}}
                  </div>
                  <div class="van-hairline--bottom"></div>
                  <div>
                    <div style="padding:5px; display:inline;float:left;">
                      <van-image :src="item.avatar" width="80" height="80" />
                    </div>
                    <div style="margin-top:7px;margin-left:10px;display:inline;float:left;">
                      <div style="margin-top:10px;color:#000;font-size:20px;">{{item.name}}</div>
                      <div style="margin-top:10px;font-size:14px;color:#999999;">{{item.title}}</div>
                    </div>
                    <div
                      :style="'font-size:20px;float:right;display:inline;margin-top:10px;color:'+['Orange','gray','red','green'][item.status]"
                    >{{['待就诊','取消','未就诊','已就诊'][item.status]}}</div>
                  </div>
                </template>
              </van-cell>
            </van-list>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="待就诊">
          <van-pull-refresh
            style="height: 100%;overflow: auto;"
            v-model="isLoading"
            @refresh="loadData"
          >
            <van-list
              ref="reserveList0"
              id="reserveList0"
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
              class="reserve_list"
            >
              <van-cell
                v-for="(item, index) in classifiedList[0]"
                :key="index"
                class="item_container"
                isLink
                :to="'history/detail?id='+item.id"
                @click="setItemId(item.id)"
              >
                <template #title>
                  <div style="font-size:16px;color:#999999;">
                    <van-icon name="clock-o" />
                    &nbsp;{{item.arrangeDate}}&nbsp;{{item.halfTime=='am'?'上午':'下午'}}&nbsp;{{item.startTime}}-{{item.endTime}}
                  </div>
                  <div class="van-hairline--bottom"></div>
                  <div>
                    <div style="padding:5px; display:inline;float:left;">
                      <van-image :src="item.avatar" width="80" height="80" />
                    </div>
                    <div style="margin-top:7px;margin-left:10px;display:inline;float:left;">
                      <div style="margin-top:10px;color:#000;font-size:20px;">{{item.name}}</div>
                      <div style="margin-top:10px;font-size:14px;color:#999999;">{{item.title}}</div>
                    </div>
                    <div
                      :style="'font-size:20px;float:right;display:inline;margin-top:10px;color:'+['Orange','gray','red','green'][item.status]"
                    >{{['待就诊','取消','未就诊','已就诊'][item.status]}}</div>
                  </div>
                </template>
              </van-cell>
            </van-list>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="取消">
          <van-pull-refresh
            style="height: 100%;overflow: auto;"
            v-model="isLoading"
            @refresh="loadData"
          >
            <van-list
              ref="reserveList1"
              id="reserveList1"
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
              class="reserve_list"
            >
              <van-cell
                v-for="(item, index) in classifiedList[1]"
                :key="index"
                class="item_container"
                isLink
                :to="'history/detail?id='+item.id"
                @click="setItemId(item.id)"
              >
                <template #title>
                  <div style="font-size:16px;color:#999999;">
                    <van-icon name="clock-o" />
                    &nbsp;{{item.arrangeDate}}&nbsp;{{item.halfTime=='am'?'上午':'下午'}}&nbsp;{{item.startTime}}-{{item.endTime}}
                  </div>
                  <div class="van-hairline--bottom"></div>
                  <div>
                    <div style="padding:5px; display:inline;float:left;">
                      <van-image :src="item.avatar" width="80" height="80" />
                    </div>
                    <div style="margin-top:7px;margin-left:10px;display:inline;float:left;">
                      <div style="margin-top:10px;color:#000;font-size:20px;">{{item.name}}</div>
                      <div style="margin-top:10px;font-size:14px;color:#999999;">{{item.title}}</div>
                    </div>
                    <div
                      :style="'font-size:20px;float:right;display:inline;margin-top:10px;color:'+['Orange','gray','red','green'][item.status]"
                    >{{['待就诊','取消','未就诊','已就诊'][item.status]}}</div>
                  </div>
                </template>
              </van-cell>
            </van-list>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="未就诊">
          <van-pull-refresh
            style="height: 100%;overflow: auto;"
            v-model="isLoading"
            @refresh="loadData"
          >
            <van-list
              ref="reserveList2"
              id="reserveList2"
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
              class="reserve_list"
            >
              <van-cell
                v-for="(item, index) in classifiedList[2]"
                :key="index"
                class="item_container"
                isLink
                :to="'history/detail?id='+item.id"
                @click="setItemId(item.id)"
              >
                <template #title>
                  <div style="font-size:16px;color:#999999;">
                    <van-icon name="clock-o" />
                    &nbsp;{{item.arrangeDate}}&nbsp;{{item.halfTime=='am'?'上午':'下午'}}&nbsp;{{item.startTime}}-{{item.endTime}}
                  </div>
                  <div class="van-hairline--bottom"></div>
                  <div>
                    <div style="padding:5px; display:inline;float:left;">
                      <van-image :src="item.avatar" width="80" height="80" />
                    </div>
                    <div style="margin-top:7px;margin-left:10px;display:inline;float:left;">
                      <div style="margin-top:10px;color:#000;font-size:20px;">{{item.name}}</div>
                      <div style="margin-top:10px;font-size:14px;color:#999999;">{{item.title}}</div>
                    </div>
                    <div
                      :style="'font-size:20px;float:right;display:inline;margin-top:10px;color:'+['Orange','gray','red','green'][item.status]"
                    >{{['待就诊','取消','未就诊','已就诊'][item.status]}}</div>
                  </div>
                </template>
              </van-cell>
            </van-list>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="已就诊">
          <van-pull-refresh
            style="height: 100%;overflow: auto;"
            v-model="isLoading"
            @refresh="loadData"
          >
            <van-list
              ref="reserveList3"
              id="reserveList3"
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
              class="reserve_list"
            >
              <van-cell
                v-for="(item, index) in classifiedList[3]"
                :key="index"
                class="item_container"
                isLink
                :to="'history/detail?id='+item.id"
                @click="setItemId(item.id)"
              >
                <template #title>
                  <div style="font-size:16px;color:#999999;">
                    <van-icon name="clock-o" />
                    &nbsp;{{item.arrangeDate}}&nbsp;{{item.halfTime=='am'?'上午':'下午'}}&nbsp;{{item.startTime}}-{{item.endTime}}
                  </div>
                  <div class="van-hairline--bottom"></div>
                  <div>
                    <div style="padding:5px; display:inline;float:left;">
                      <van-image :src="item.avatar" width="80" height="80" />
                    </div>
                    <div style="margin-top:7px;margin-left:10px;display:inline;float:left;">
                      <div style="margin-top:10px;color:#000;font-size:20px;">{{item.name}}</div>
                      <div style="margin-top:10px;font-size:14px;color:#999999;">{{item.title}}</div>
                    </div>
                    <div
                      :style="'font-size:20px;float:right;display:inline;margin-top:10px;color:'+['Orange','gray','red','green'][item.status]"
                    >{{['待就诊','取消','未就诊','已就诊'][item.status]}}</div>
                  </div>
                </template>
              </van-cell>
            </van-list>
          </van-pull-refresh>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import BScroll from "better-scroll";
export default {
  components: {},
  data() {
    return {
      tabIndex: 0,
      loading: false,
      finished: true,
      allList: [],
      classifiedList: [],

      show: false,
      title: "标题",
      clickId: 0,
      isLoading: false,
    };
  },
  created() {},
  methods: {
    setItemId(id) {
      console.log(id);
      this.clickId = id;
    },
    tabChange(val) {
      this.tabIndex = val;
      console.log(val);
    },
    loadData() {
      this.$axios
        .get("/reserve-bill/getMyList", {
          params: { userId: this.$user.id },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code == "ok") {
            //加载数据
            this.allList = res.data;
            //分类
            for (var i = 0; i < 4; i++) {
              //状态0-3，待就诊，取消，未就诊，已就诊
              this.classifiedList[i] = this.allList.filter(
                (a) => a.status == i
              );
            }
          }
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },

    onLoad() {},

    // 返回
    back() {
      this.$back();
    },
  },

  mounted() {
    this.loadData();

    var h = this.$refs.allPage.offsetHeight;
    console.log("h==" + h);
    console.log("document.body.clientHeight==" + document.body.clientHeight);
  },
  activated() {
    if (sessionStorage.listChange) {
      sessionStorage.removeItem("listChange");
      var itemId = this.$route.meta.itemId;
      if ((this.$route.meta.from = "/reserve")) {
        //预约下单后过来的
        this.loadData();
      } else {
        //预约详情取消过来的
        console.log("itemId===" + itemId);
        //刷新数据状态
        this.$axios
          .get("/reserve-bill/getDetail", { params: { id: itemId } })
          .then((res) => {
            var data = res.data;
            //修改列表中当前这一条的数据状态
            this.allList.forEach((his) => {
              if (his.id == itemId) {
                his.status = data.status;
              }
            });
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log("from==" + from.path);
      if (from.path === "/reservation/detail") {
        //判断是从哪个tab进去的
        console.log("befor enter=" + to.meta.tabIndex);
        if (to.meta.tabIndex == 0) {
          document.getElementById("reserveList").scrollTop =
            to.meta.scollTopPosition;
        } else {
          var index = to.meta.tabIndex - 1;
          console.log("get index=" + index);
          document.getElementById("reserveList" + index).scrollTop =
            to.meta.scollTopPosition;
        }
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    console.log(to.path);
    from.meta.tabIndex = this.tabIndex;
    if (from.meta.keepAlive) {
      if (this.tabIndex == 0) {
        from.meta.scollTopPosition = document.getElementById(
          "reserveList"
        ).scrollTop;
      } else {
        var index = this.tabIndex - 1;
        console.log(index);
        from.meta.scollTopPosition = document.getElementById(
          "reserveList" + index
        ).scrollTop;
      }
      from.meta.itemId = this.clickId;
    }

    next();
  },
};
</script>

<style lang="less">
.item_container {
  width: 100%;
  overflow: hidden;
  margin-bottom: 7px;
}
.item_container span {
  display: block;
  float: left;
}

.one_report {
  margin-bottom: 15px;
}

.form_text {
  margin: 15px 0 30px;
  padding: 0 12px;
}

.form_btn {
  text-align: right;
}

.form_btn button {
  margin-left: 10px;
}

.one_report_data {
  margin-bottom: 20px;
  padding: 0 22px;
  div {
    line-height: 30px;
  }
}
.item_container {
  width: 100%;
  overflow: hidden;
}
.item_container span {
  display: block;
  float: left;
}
</style>
<style lang="scss" scoped>
.recommand-wrap {
  padding-top: 10px;
  padding-bottom: 5px;
  background: #fff;
  padding-left: 0.24rem;
  width: 100%;
  .title {
    position: relative;
    height: 40px;
    display: flex;
    padding: 12px 0;
    box-sizing: border-box;
    .title-img {
      width: 15px;
      height: 15px;
    }
    .title-hotrec {
      font-size: 16px;
      margin-left: 4px;
    }
    .title-allrec {
      position: absolute;
      padding-top: 2px;
      font-size: 13px;
      right: 20px;
      color: gray;
    }
  }
  .cont {
    list-style: none;
    // overflow-x: scroll;
    white-space: nowrap;
    font-size: 12px;
    text-align: center;
    padding-right: 0.24rem;
    .cont-item {
      position: relative;
      display: inline-block;
      padding: 0.06rem 0 0.2rem;
      width: 1.5rem;
      margin: 0 0.1rem;
      .cont-dest {
        margin: 0.1rem 0;
        font-size: 0.4rem;
      }
    }
  }
}
.contentStyle {
  -webkit-box-orient: vertical;
  height: 44px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  margin-top: 4px;
}
</style>

<style lang="less">
.tabs {
  .van-nav-bar .van-icon,
  .van-nav-bar__text {
    color: #20a0ff;
  }
  .van-tab {
    flex-basis: 20% !important;
    font-size: 18px !important;
  }
}
</style>
